import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo-ortom-white.svg';
import SignUp from './LandingPage/SignUp/SignUp';
import Contact from './LandingPage/Contact/Contact';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer" id="footer">
        <div className="small-gap" />
        <div className="container mobile-container ">
          <div className="columns">
            <div className="column is-8">
              <Contact />
            </div>
            {/* <div className="column is-2" /> */}
            <div className="column is-4">
              <SignUp />
            </div>
          </div>
        </div>

        <div id="photo-container">
          <div className="photobanner">
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
            <img src={logo} alt="ortom logo" />
          </div>
        </div>
        <div className="footerMenu container">
          <p className="footerMenu--item legal">&#169;2025</p>
          <p className="footerMenu--item legal">
            <a
              href="https://www.linkedin.com/in/tomliptrot1/"
            >
              LINKEDIN
            </a>
          </p>
          <p className="footerMenu--item legal">
            <a
              href="https://clutch.co/profile/ortom"
            >
              CLUTCH.CO
            </a>
          </p>
          <p className="footerMenu--item legal">
            <Link
              to="/privacy"
            >
              TERMS &#38; PRIVACY
            </Link>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
